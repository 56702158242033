import { I18nMessageFunction } from '@/types'

export default {
  title: 'Injective DMM',

  common: {
    you: 'you',
    open: 'Open',
    link: 'Link',
    close: 'Close',
    search: 'Search',
    ranking: 'Ranking',
    viewDetails: 'View Details',
    emptyResult: 'No participants yet',
    lastUpdatedTime: 'Last updated time'
  },

  epoch: {
    prefix: 'Epoch',
    epochUppercase: 'EPOCH',
    pastEpoch: 'Past epochs'
  },

  market: {
    all: 'All',
    spot: 'Spot',
    futures: 'Futures',
    perpetuals: 'Perpetuals',
    filter_markets: 'Filter Markets'
  },

  overall: {
    title: 'Overall Ranking',
    dedicatedMarketMaker: 'Dedicated Market Maker'
  },

  nav: {
    tradingRewards: 'Trade & Earn',
    currentEpochRecords: 'Score records in current epoch',
    dedicatedMarketMakingRewards: 'Dedicated Market Making',
    dedicatedMarketMakingRankings: 'Dedicated market making ranking'
  },

  ranking: {
    rank: 'Rank',
    elcs: 'ELCS',
    evcs: 'EVCS',
    volume: 'Volume',
    account: 'account',
    ranking: 'Ranking',
    overall: 'Overall',
    elcsBuy: 'Buy score',
    elcsSell: 'Sell score',
    perAddress: 'Per Address',
    title: 'Market breakdown',
    address: 'Injective Chain Address',
    lastUpdatedTime: 'Last updated time',
    marketRewardFactor: 'Market reward factor',
    perAddressByMarket: 'Per Address By Market',
    evcsTitle: 'Exchange Volume contribution score (EVCS)',
    elcsTitle: 'Exchange Liquidity contribution score (ELCS)'
  },

  parameters: {
    priceBias: 'Price bias',
    title: 'Contribution Parameters',
    downloadAsJson: 'Download as JSON',
    marketRewardFactor: 'Market Reward Factor',
    orderValueMultiplier: 'ORDER VALUE multiplier',
    exportLinkDescription: 'Need a consolidated view including other markets?',
    description:
      'Detailed parameters that we use to calculate your contribution scores.'
  },

  resource: {
    perp: 'Perp',
    spot: 'Spot',
    title: 'Resources',
    markets: 'Markets',
    elcs: 'Elcs Reward Factor',
    evcs: 'Evcs Reward Factor',
    modalTitle: 'Market reward factors',
    rewardFactors: 'Market Reward Factors',
    rewardFactorsDescription:
      'Each market has a different Market Reward Factor to encourage participations.',
    integrationGuide: {
      title: 'Integration Guide',
      description:
        'Documentation of how to start trading on Injective as well as further details on DMM rewards'
    }
  },

  summary: {
    tvl: 'TVL',
    apy: 'APY',
    elcs: 'ELCS',
    evcs: 'EVCS',
    title: 'Summary',
    expected: 'Expected ',
    pastEpochs: 'Past epochs',
    rewardsInj: 'Rewards (INJ)',
    rewardsUsd: 'Rewards (USD)',
    address: 'Injective Chain Address',
    elcsTotalTokens: 'INJ tokens for ELCS pool',
    evcsTotalTokens: 'INJ tokens for EVCS pool',
    tokensDistributionTitle: 'tokens distribution',
    evcsTitle: 'Exchange volume contribution score (EVCS)',
    elcsTitle: 'Exchange Liquidity contribution score (ELCS)',
    elcsTotalTokensPercentage:
      '{percentage} % of total INJ token dedicated for this epoch',
    evcsTotalTokensPercentage:
      '{percentage} % of total INJ token dedicated for this epoch'
  },

  tooltip: {
    elcs: 'The absolute score based on the liquidity contributed to Injective Protocol.',
    elcsPercentage:
      'The participant is entitled to receive this percentage of the distributed INJ tokens assigned to the ELCS pool.',
    evcs: 'The absolute score based on the volume contributed to Injective Protocol.',
    evcsPercentage:
      'The participant is entitled to receive this percentage of the distributed INJ tokens assigned to the EVCS pool.',
    elcsRewardFactor: "This factor will be applied to this market's ELCS.",
    evcsRewardFactor: "This factor will be applied to this market's EVCS.",
    buyScore:
      'The absolute score based on the buy side orders provided in this market.',
    sellScore:
      'The absolute score based on the sell side orders provided in this market.',
    volume: 'The real volume contribution towards Injective Protocol.',
    priceBias:
      'Price Bias represents the tiers of spreads. If the resting order’s price falls within a tier, then the ELCS score will be multiplied according to the Order Value Multiplier. The tighter the spread, the higher the multiplier.'
  },

  address: {
    enterAddress: 'Please enter an address',
    enterInjAddress: 'Please enter a valid Injective address'
  },

  banner: {
    epochLaunched: ({ named, interpolate }: I18nMessageFunction) =>
      interpolate([
        'The new Open Liquidity Program has launched!',
        named('link')
      ]),
    epochDiscontinued:
      'This DMM Program will be discontinued at the end of epoch 20.'
  }
}
