import { TokenService, TokenPrice } from '@injectivelabs/sdk-ui-ts'
import {
  IndexerGrpcAccountPortfolioApi,
  IndexerGrpcDerivativesApi,
  IndexerGrpcSpotApi,
  ChainGrpcBankApi
} from '@injectivelabs/sdk-ts'
import { CoinGeckoApi } from '@injectivelabs/token-utils'
import { LocalStorage } from '@injectivelabs/utils'
import {
  CHAIN_ID,
  COIN_GECKO_OPTIONS,
  NETWORK,
  ENDPOINTS
} from './utils/constants'

export const indexerGrpcAccountPortfolioApi =
  new IndexerGrpcAccountPortfolioApi(ENDPOINTS.indexer)
export const indexerDerivativesApi = new IndexerGrpcDerivativesApi(
  ENDPOINTS.indexer
)
export const indexerSpotApi = new IndexerGrpcSpotApi(ENDPOINTS.indexer)
export const bankApi = new ChainGrpcBankApi(ENDPOINTS.grpc)
export const tokenService = new TokenService({
  chainId: CHAIN_ID,
  network: NETWORK
})
export const coinGeckoApi = new CoinGeckoApi(COIN_GECKO_OPTIONS)
export const tokenPrice = new TokenPrice(COIN_GECKO_OPTIONS)

export const localStorage: LocalStorage = new LocalStorage(
  `inj-dmm-v11-${NETWORK}-${process.env.VITE_ENV || 'mainnet'}`
)
