/* eslint-disable no-console */
import {
  ErrorType,
  isThrownException,
  ThrownException,
  UnspecifiedErrorCode
} from '@injectivelabs/exceptions'
import { StatusCodes } from 'http-status-codes'
import { defineNuxtPlugin } from '#imports'
import { IS_PRODUCTION } from '@/app/utils/constants'

const isErrorExcludedFromToast = (error: any): boolean => {
  const disabledPatterns = [
    /^(dmm \[inj)(.*)(\] didn't participate in the epoch \[epoch_)(.*)(])/
  ]

  const errorMessage =
    typeof error === 'object' && error !== null ? error.message : error || ''

  return disabledPatterns.some((pattern) => pattern.test(errorMessage))
}

const isErrorExcludedFromReporting = (error: any): boolean => {
  const disabledMessages = [
    'Your country is restricted from trading on this relayer',
    'Your IP address is detected as a proxy or you are using a VPN provider.',
    'Please make sure your Ledger device is connected, unlocked and your Ethereum app is open'
  ]
  const errorMessage =
    typeof error === 'object' && error !== null ? error.message : error || ''

  return (
    errorMessage.startsWith('Metamask:') ||
    errorMessage.includes('MetaMask') ||
    errorMessage.includes('Metamask') ||
    errorMessage.includes('metamask') ||
    errorMessage.includes('metamask') ||
    errorMessage.startsWith('cannot find epoch with id') ||
    errorMessage.startsWith('Failed to fetch') ||
    errorMessage.startsWith('Network Error') ||
    disabledMessages.includes(errorMessage) ||
    isErrorExcludedFromToast(error)
  )
}

const parseMessage = (error: any): string => {
  const message = error.message || error

  if (message.toLowerCase().includes('response closed')) {
    return 'Something happened. Please refresh the page.'
  }

  return message
}

const reportToBugSnag = (error: ThrownException) => {
  if (!IS_PRODUCTION) {
    console.warn(error.toCompactError().message)
    console.error(error)

    return
  }

  if ([ErrorType.Unspecified, ErrorType.WalletError].includes(error.type)) {
    console.warn(error.toCompactError().message)
    console.error(error)
  }
}

const reportUnknownErrorToBugsnag = (error: Error) => {
  if (!IS_PRODUCTION) {
    console.error({ error, stack: error.stack })
  }

  const newError = new Error(
    `The ${error.message} is not handled as an Exception - ${error.stack}`
  )

  console.warn(newError.message, newError.stack)
}

const reportToUser = (error: ThrownException) => {
  const { error: errorToast } = useNotifications()

  // Timedout requests happening in the background should not be reported to the user
  if (
    error.type === ErrorType.HttpRequest &&
    error.code === StatusCodes.REQUEST_TOO_LONG
  ) {
    return
  }

  const shouldIgnoreToast = error.code === UnspecifiedErrorCode

  if (shouldIgnoreToast) {
    return
  }

  errorToast({ title: parseMessage(error) })
}

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.config.errorHandler = (error, context) => {
    console.warn(error, context, (error as any).stack)
  }

  window.onunhandledrejection = function (event: PromiseRejectionEvent) {
    const error = event.reason

    if (!IS_PRODUCTION) {
      return
    }

    if (!isThrownException(error)) {
      reportUnknownErrorToBugsnag(error)
    } else {
      reportToBugSnag(error)
    }
  }

  const errorHandler = (error: ThrownException) => {
    if (!isThrownException(error) || !isErrorExcludedFromReporting(error)) {
      reportUnknownErrorToBugsnag(error)
    }

    if (!isErrorExcludedFromToast(error)) {
      reportToUser(error)
    }
  }

  return {
    provide: {
      onError: errorHandler
    }
  }
})
