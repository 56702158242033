import { NUMBER_REGEX } from '@injectivelabs/sdk-ui-ts'
import { required, email, min, max, between } from '@vee-validate/rules'
import { getEthereumAddress } from '@injectivelabs/sdk-ts'
import { defineRule } from 'vee-validate'

export const errorMessages = {
  injaddress: () => 'This field is not a valid Injective address',
  required: () => 'This field is required',
  positiveNumber: () => 'This field is not a valid number',
  email: () => 'This field should be a valid email',
  between: (_field: string, params: Record<string, any>) =>
    `${
      params.max <= params.min
        ? `Your input value of ${params._value_} cant be higher than ${params.max}`
        : `This field should be between ${params.min} and ${params.max}`
    }`,
  max: () => 'This field should be less than {length}',
  min: () => 'This field should be greater than {length}'
} as Record<string, (field?: string, params?: Record<string, any>) => string>

export const defineGlobalRules = () => {
  defineRule('email', email)
  defineRule('between', between)
  defineRule('min', min)
  defineRule('max', max)
  defineRule('required', required)

  defineRule('injAddress', (value: string) => {
    try {
      getEthereumAddress(value)

      return true
    } catch (error: any) {
      return errorMessages.injAddress()
    }
  })

  defineRule('positiveNumber', (value: string) => {
    if (NUMBER_REGEX.test(value)) {
      return true
    }

    return errorMessages.positiveNumber()
  })
}

export default defineNuxtPlugin(() => {
  defineGlobalRules()
})
