import {
  Network,
  isDevnet,
  isTestnet,
  getNetworkEndpoints
} from '@injectivelabs/networks'
import { ChainId, EthereumChainId } from '@injectivelabs/ts-types'

export const IS_DEVELOPMENT: boolean =
  import.meta.env.NODE_ENV === 'development'

export const IS_PRODUCTION: boolean = import.meta.env.NODE_ENV === 'production'
export const IS_MAINNET_STAGING: boolean =
  import.meta.env.VITE_ENV === 'staging'
export const METRICS_ENABLED: boolean =
  import.meta.env.METRICS_ENABLED === 'true'

export const BASE_URL = import.meta.env.VITE_BASE_URL || ''

export const UI_DEFAULT_DISPLAY_DECIMALS = 4
export const UI_DEFAULT_MAX_DISPLAY_DECIMALS = 6
export const UI_DEFAULT_DMM_DECIMALS = 2

export const NETWORK: Network =
  (import.meta.env.VITE_NETWORK as Network) || Network.Mainnet
export const ENDPOINTS = getNetworkEndpoints(NETWORK)
export const IS_DEVNET = isDevnet(NETWORK)
export const IS_TESTNET = isTestnet(NETWORK)

export const COIN_GECKO_OPTIONS = {
  apiKey: import.meta.env.VITE_COINGECKO_KEY as string,
  baseUrl: import.meta.env.VITE_COINGECKO_KEY
    ? 'https://pro-api.coingecko.com/api/v3'
    : 'https://api.coingecko.com/api/v3'
}

const getChainId = (): ChainId => {
  const envChainId = import.meta.env.VITE_CHAIN_ID

  if (envChainId) {
    return envChainId as ChainId
  }

  if (IS_TESTNET) {
    return ChainId.Testnet
  }

  if (IS_DEVNET) {
    return ChainId.Devnet
  }

  return ChainId.Mainnet
}

const getEthereumChainId = (): EthereumChainId => {
  const envEthereumChainId = import.meta.env.VITE_ETHEREUM_CHAIN_ID

  if (envEthereumChainId) {
    return parseInt(envEthereumChainId.toString())
  }

  if (IS_TESTNET || IS_DEVNET) {
    return EthereumChainId.Goerli
  }

  return EthereumChainId.Mainnet
}

export const CHAIN_ID: ChainId = getChainId()
export const ETHEREUM_CHAIN_ID: EthereumChainId = getEthereumChainId()
